<template>


  <!--  Content Parameters-->
  <div v-if="!isStyle">
    <ws-text-field
        v-model="element.text"
        @change="$emit('update-element' , element)"
        :label="$t('Title')"
        :placeholder="$t('EnterText')"
        clearable
        avalon-style
    />

    <ws-select
        @input="$emit('input' , element.config)"
        :items="buttonActions"
        :label="$t('Action')"
        :placeholder="$t('ChooseButtonAction')"
        v-model="element.config.action"
        class="mt-5"
        avalon-style
    />

    <div
        v-if="['sidebar' , 'dialog'].includes(element.config.action)"
    >
      <image-param-picker
          v-if="element.config.action === 'sidebar'"
          class="pt-5"
          @change="$emit('input' , element.config)"
          v-model="element.config.sidebar_img"
      />

      <ws-text-field
          class="pt-5"
          @change="$emit('input' , element.config)"
          v-model="element.config.sidebar_title"
          :label="$t(element.config.action + '_title')"
          :placeholder="$t('EnterTitle')"
          avalon-style
      />
      <ws-text-field
          v-if="element.config.action === 'sidebar'"
          class="mt-4"
          @change="$emit('input' , element.config)"
          v-model="element.config.sidebar_text"
          :label="$t('Subtitle')"
      />

      <ws-avalon-text-input
          class="mt-4"
          @change="$emit('input' , element.config)"
          :label="$t('SideBarText')"
          v-model="element.config.sidebar_additional_text" />
    </div>

  </div>

  <!--  Style Parameters-->
  <div v-else>

    <!-- Button Style Picker-->
    <v-sheet  :style="`border : 1px solid ${wsBACKGROUND}; border-radius : 8px`">
      <!-- Primary Style-->
      <div @click="changeButtonStyle('primary')" class="pointer d-flex align-center py-4" >

        <ws-check-box
            @prevent="changeButtonStyle('primary')"
            prevent-select radio :selected-color="wsACCENT" :value="element.config.style === 'primary'"  :small="false" :color="wsACCENT" class="mr-1 ml-2" />
        <v-sheet
            :color="wsACCENT"
            class="d-flex align-center justify-center mr-3"
            min-width="84" min-height="42"
            dark
        >
          <h3 :style="`color : white`" >
            Ab
            <v-icon color="white" v-if="element.config.display_icon">
              {{ element.config.icon }}
            </v-icon>
          </h3>
        </v-sheet>
        <h5 :style="`color : ${wsACCENT}`">{{ $t('PrimaryButton') }}</h5>
      </div>
      <v-divider :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Secondary Style-->
      <div
          @click="changeButtonStyle('secondary')"
          class="pointer d-flex align-center py-4" >

        <ws-check-box
            :value="element.config.style === 'secondary' "
            :selected-color="wsACCENT"
            :color="wsACCENT"
            @prevent="changeButtonStyle('secondary')"
            :small="false"
            class="mr-1 ml-2"
            prevent-select radio
        />
        <v-sheet
            :style="`border : 2px solid ${wsACCENT}`"
            class="d-flex align-center justify-center mr-3"
            min-width="84" min-height="42"
        >
          <h3 :style="`color : ${wsACCENT}`">
            Ab
            <v-icon :color="wsACCENT" v-if="element.config.display_icon">
              {{ element.config.icon }}
            </v-icon>
          </h3>
        </v-sheet>
        <h5 :style="`color : ${wsACCENT}`">{{ $t('SecondaryButton') }} </h5>
      </div>
      <v-divider :style="`border-color : ${wsBACKGROUND}`" />
      <!-- Text Style-->
      <div
          @click="changeButtonStyle('text')"
          class="pointer d-flex align-center py-4" >

        <ws-check-box
            @prevent="changeButtonStyle('text')"
            :value="element.config.style === 'text' "
            :selected-color="wsACCENT"
            :color="wsACCENT"
            :small="false"
            prevent-select radio
            class="mr-1 ml-2"
        />
        <v-sheet
            color="transparent"
            class="d-flex align-center mr-3"
            min-width="84" min-height="42"
        >
          <h3 :style="`color : ${wsACCENT}`" class="text-no-wrap">Ab
            <v-icon :color="wsACCENT" v-if="element.config.display_icon">
              {{ element.config.icon }}
            </v-icon>
          </h3>
        </v-sheet>
        <h5 :style="`color : ${wsACCENT}`">{{ $t('TextButton') }} </h5>
      </div>
    </v-sheet>


    <v-expand-transition>
      <a-parameter-slider
          v-if="element.config.style !== 'text'"
          @change="$emit('input' , element.config)"
          v-model="element.config.rounded"
          :default="0"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          label="Rounded"
          min="0"
          max="50"
      />
    </v-expand-transition>


    <a-parameter-switch
        class="mt-5"
        @change="editDisplayIcon"
        label="DisplayIcon"
        v-model="element.config.display_icon"
    />

    <v-expand-transition>
      <div v-if="element.config.display_icon"  class="pt-5">
        <ws-select
            v-model="element.config.icon"
            :items="buttonsIconsSelect"
            :label="$t('ElementStyle')"
            :append-icon="element.config.icon"
            append-inner
            avalon-style
            class="mb-8"
        />
      </div>
    </v-expand-transition>

    <ws-accordion
        :items="styleNavigationSelect"
    >
      <template #item.button>

      </template>
      <template #item.color>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            :block-style="blockStyle"
        />
      </template>
      <template #item.font>
        <element-style-settings
            @input="updateSettings"
            v-model="element.config"
            :element="element"
            fonts
        />
      </template>
    </ws-accordion>

  </div>
</template>

<script>
import {mapState} from "vuex";
import wsAvalonTextInput from "@/components/AvalonEditor/UI/wsAvalonTextInput/wsAvalonTextInput";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";
import elementStyleSettings from "@/components/AvalonEditor/Dialogs/elements/elementStyleSettings";

export default {
  name: "avalonTextSettings",
  components : {
    wsAvalonTextInput,
    imageParamPicker,
    elementStyleSettings
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    isStyle : {
      type : Boolean,
      default : false
    },
    element : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    }
  },
  data() {
    return {
      config : {}
    }
  },
  computed : {
    ...mapState('avalon',['course' , 'pagesSelect' , 'coursesSelect']),

    styleNavigationSelect() {
      return [
        { name : this.$t('Color') , value : 'color' },
        { name : this.$t('Fonts') , value : 'font' }
      ]
    },
    buttonsIconsSelect() {
      return [
        { text : this.$t('Arrow') , value : 'mdi-arrow-right'  , right_icon : 'mdi-arrow-right' },
        { text : this.$t('Plus') , value : 'mdi-plus' , right_icon : 'mdi-plus' },
      ]
    },

    buttonActions() {
      return [
        { text : this.$t('OpenDetailsSidebar') , value : "sidebar" },
        { text : this.$t('OpenDetailsDialog') , value : "dialog" },
      ]
    },

  },
  methods : {
    editDisplayIcon(value) {
      if ( value && !this.element.config.icon ) {
        this.element.config.icon = this.buttonsIconsSelect[0].value
        this.element = this.COPY(this.element)
      }
      this.$emit('input' , this.element.config)
    },
    changeButtonStyle(value) {
      this.element.config.style = value
      this.element = this.COPY(this.element)
      this.$emit('input' , this.element.config)
    },
    updateSettings() {
      this.element = this.COPY(this.element)
      this.$emit('input' , this.COPY(this.element.config))
    },
  },
  mounted() {
    this.config = this.COPY(this.value)
  }

}
</script>

<style scoped>

</style>